import { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Spinner, Flex } from "@chakra-ui/react";
import { useStoreClient } from "../../hooks/storeClient";

export function LoaderStoreClient() {
  const { company } = useStoreClient();
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (company.id) {
      if (company.has_create_client_independent) {
        navigate(`/cadastro/${slug}`);
      } else {
        navigate(`/cadastro/${slug}/indisponivel`);
      }
    }
  }, [company, navigate, slug]);

  return (
    <Flex alignItems="center" justify="center" display="flex">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color={company.color}
        size="xl"
      />
    </Flex>
  );
}
