import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { BornDateInput } from "../../atoms/Input/BornDateInput";
import { PhoneInput } from "../../atoms/Input/PhoneInput";
import { FormControlMaterialUI } from "../FormControlMaterialUI";
import { Stack } from "@chakra-ui/react";
interface CompleteClientFormProps {
  register: UseFormRegister<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  bg: string;
  has_cnpj: boolean;
}

export function CompleteClientForm({
  register,
  errors,
  bg,
  has_cnpj,
}: CompleteClientFormProps) {
  return (
    <Stack spacing={2}>
      <FormControlMaterialUI
        text="Whatsapp"
        name="phone"
        register={register}
        isRequired={true}
        bg={bg}
        error={errors.phone}
        input={PhoneInput}
      />

      <FormControlMaterialUI
        text="Data de aniversário"
        register={register}
        name="born_date"
        isRequired={false}
        error={errors.born_date}
        bg={bg}
        input={BornDateInput}
      />
    </Stack>
  );
}
