import { Text, Flex, Box } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { api } from "../../services/api";
import { useParams } from "react-router-dom";
import Confetti from "react-confetti";
import * as htmlToImage from "html-to-image";
import { Coupon } from "../../components/templates/Coupon";
import { formatDateBr } from "../../utils/formatDate";
import { DinamicSolidButton } from "../../components/atoms/Button/DinamicSolidButton";
import { TitleDefault } from "../../components/atoms/Text/TitleDefault";
import { useStoreClient } from "../../hooks/storeClient";
import download from "downloadjs";

interface CouponProps {
  id: string;
  code: string;
  expiration_date: Date;
  client_id: string;
  client: {
    name: string;
  };
  bonus: {
    name: string;
    bonuses_message?: string;
    total_visits: number;
  };
}
export function EndStoreClient() {
  const { coupon_id } = useParams();
  const [coupon, setCoupon] = useState<CouponProps>({} as CouponProps);
  const [logo, setLogo] = useState("");
  const { innerWidth: width, innerHeight: height } = window;
  const handleDownloadCoupon = useCallback(async () => {
    var node = document.getElementById("coupon");
    if (node) {
      await htmlToImage.toPng(node);
      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          download(dataUrl, `cupom.png`);
        })
        .catch((err) => console.log(err));
    }
  }, []);
  const { company, replaceQuestion, whiteLabel } = useStoreClient();
  useEffect(() => {
    if (typeof coupon_id !== typeof undefined) {
      api.get(`/coupons/${coupon_id}`).then((response) => {
        setCoupon(response.data);
      });
      api.get(`/companies/logo/${company.logo}`).then((response) => {
        setLogo(response.data);
      });
    }
  }, [coupon_id, company.logo]);
  return (
    <>
      <Flex alignItems="center" direction="column">
        {coupon.id ? (
          <>
            <Confetti
              width={width - 20}
              height={height + 100}
              colors={[
                company.color,
                company.secondary_color,
                "#2b88a1",
                "#ff9000",
              ]}
            />
            <Text as="h2" fontSize="18px" mb="6px" fontWeight="regular">
              Parabéns {coupon.client.name},
            </Text>
            <Text mb="5px">
              Seu cadastro foi realizado com sucesso ! Você acaba de ganhar{" "}
              {coupon.bonus?.name} no seu próximo encontro com {replaceQuestion}{" "}
            </Text>

            <Text mb="5px">
              <b>Validade: {formatDateBr(coupon.expiration_date)}</b>
            </Text>
            <Box my={5}>
              <DinamicSolidButton
                bg={company.color}
                color="white"
                onClick={() => {
                  handleDownloadCoupon();
                }}
              >
                Fazer Download
              </DinamicSolidButton>
            </Box>
            <Coupon
              client={coupon.client}
              bonus={coupon.bonus}
              code={coupon.code}
              logo={logo}
              company={company}
              expiration_date={coupon.expiration_date}
              is_falae={whiteLabel === undefined}
            />
          </>
        ) : (
          <>
            <TitleDefault title={`Cadastro - ${company.name}`} />
            <Box mt={5}>
              <Text mt="50px">Seu cadastro foi realizado com sucesso! </Text>
            </Box>
          </>
        )}
      </Flex>
    </>
  );
}
