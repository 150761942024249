import { Checkbox as CheckboxChakra } from "@chakra-ui/checkbox";

interface IProps {
  value: any;
  label: string;
  setValue: (event: any) => void;
  color?: string;
  defaultChecked?: boolean;
  isRequired?: boolean;
}

export function Checkbox({
  value,
  label,
  setValue,
  color = "#363636",
  defaultChecked = false,
  isRequired = false
}: IProps) {
  return (
    <CheckboxChakra
      onChange={setValue}
      value={value}
      color="#787878"
      borderColor={color}
      fontWeight="400"
      fontSize="16px"
      py="6px"
      defaultChecked={defaultChecked}
      isRequired={isRequired}
    >
      {label}
    </CheckboxChakra>
  );
}
