import { Stack, Flex, Image, Skeleton } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { DinamicSolidButton } from "../../components/atoms/Button/DinamicSolidButton";
import { useToast } from "@chakra-ui/toast";
import { Input } from "../../components/Form/Input";
import { useNavigate } from "react-router-dom";
import { api } from "../../services/api";
import { Footer } from "../../components/templates/Footer";
import { Helmet } from "react-helmet";

interface WhiteLabel {
  name: string;
  logo_vertically_url: string;
  logo_horizontal_url: string;
  primary_color: string;
  form_domain: string;
  favicon_url: string;
}

export function DefaultLinkConfig() {
  const [link, setLink] = useState("");
  const toast = useToast();
  const [whiteLabel, setWhiteLabel] = useState<WhiteLabel>();

  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const handleSaveStorageLink = useCallback(() => {
    if (!whiteLabel && link.match(/falae.experienciab2s.com\/formularios\/*/)) {
      localStorage.setItem(`@falae:defaultLink`, link);
      navigate("/");
    } else if (whiteLabel && link.match(whiteLabel.form_domain)) {
      localStorage.setItem(`@falae:defaultLink`, link);
      navigate("/");
    } else {
      toast({
        title: `Link inválido`,
        position: "top",
        isClosable: true,
        duration: 5000,
        status: "error",
      });
    }
  }, [whiteLabel, link, navigate, toast]);

  useEffect(() => {
    if (
      window.location.origin !== process.env.REACT_APP_WEB_URL &&
      window.location.origin !== process.env.REACT_APP_OTHER_WEB_URL
    ) {
      api
        .get(`/whiteLabels/formDomain?form_domain=${window.location.origin}`)
        .then((response) => {
          setWhiteLabel(response.data);
          setIsLoaded(true);
        })
        .catch((err) => {
          setIsLoaded(true);
        });
    } else {
      setIsLoaded(true);
    }
  }, [setWhiteLabel]);

  return (
    <Flex minH="100vh" bg="gray.100" flexDir="column">
      {whiteLabel ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Plataforma - {whiteLabel.name}</title>
          <link rel="icon" href={whiteLabel.favicon_url} />
          <link
            rel="manifest"
            href={`${process.env.REACT_APP_API_FORMULARIO_URL}/whiteLabels/formDomain/manifest?form_domain=${whiteLabel.form_domain}`}
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>Falaê - Plataforma</title>
          <link rel="icon" href="favicon.ico" />
          <link rel="apple-touch-icon" href="logo192.png" />
          <link rel="manifest" href="/manifest.json" />
        </Helmet>
      )}

      <Flex alignItems="center" minH="95vh">
        <Flex
          flexDir="column"
          placeContent="center"
          align="center"
          width="100%"
        >
          <Skeleton isLoaded={isLoaded}>
            <Image
              src={
                window.location.origin !== process.env.REACT_APP_WEB_URL &&
                window.location.origin !== process.env.REACT_APP_OTHER_WEB_URL
                  ? whiteLabel?.logo_vertically_url
                  : "https://b2s.s3.sa-east-1.amazonaws.com/falae/logo_sem_fundo_vertical.png"
              }
              height={250}
              my={5}
            />
          </Skeleton>
          <Skeleton isLoaded={isLoaded}>
            <Stack
              alignItems="center"
              flexDir="column"
              justify="space-between"
              my={5}
            >
              <Input
                name="link"
                label="Digite o link que deseja definir como padrão no PWA"
                onChange={(e: any) => setLink(e.target.value)}
              />
              <DinamicSolidButton
                bg={whiteLabel ? whiteLabel.primary_color : "#0088a1"}
                color="#fff"
                onClick={handleSaveStorageLink}
              >
                Redirecionar
              </DinamicSolidButton>
            </Stack>
          </Skeleton>
        </Flex>
      </Flex>
      <Footer whiteLabel={whiteLabel} />
    </Flex>
  );
}
