import { Checkbox, CheckboxGroup, Flex, Input } from "@chakra-ui/react";

import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { Form } from "../../../components/atoms/Form";
import { useFormClient } from "../../../hooks/formClient";
import { TitleDefault } from "../../../components/atoms/Text/TitleDefault";
import { SubtitleDefault } from "../../../components/atoms/Text/SubtitleDefault";
import { SearchSimpleLink } from "../../../components/molecules/SearchSimpleLink";
import { PrivacityText } from "../../../components/atoms/Text/PrivacityText";
import { FormControlMaterialUI } from "../../../components/templates/FormControlMaterialUI";
import { EmailInput } from "../../../components/atoms/Input/EmailInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { HTMLText } from "../../../components/atoms/Div/HTMLText";
import { useEffect } from "react";

interface IProps {
  handle: (data: any) => void;
  setError?: any;
  error: string;
}

const updateClientFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Formato inválido")
    .required("E-mail é obrigatório")
    .min(3, "O e-mail deve conter no mínimo 3 caracteres"),
  approved: yup.boolean().nullable(),
});
export function UpdateClientOnePage({ handle, error }: IProps) {
  const { search, company } = useFormClient();
  const { answer_id } = useParams();
  const { register, handleSubmit, formState, setError } = useForm({
    mode: "onBlur",
    resolver: yupResolver(updateClientFormSchema),
  });

  useEffect(() => {
    if (error !== "") {
      setError(
        "email",
        {
          message: error,
        },
        {
          shouldFocus: true,
        }
      );
    }
  }, [error, setError]);

  const { errors } = formState;

  return (
    <>
      <TitleDefault title="Login" />
      <SubtitleDefault
        subtitle={
          search.title
            ? search.title
            : `Pesquisa de Satisfação - ${company.name}`
        }
      />
      {search.skip_registration_decision && (
        <HTMLText html={search.client_message} />
      )}
      <Form id="login" onSubmit={handleSubmit(handle)}>
        <FormControlMaterialUI
          is_totem={search.is_totem}
          text="E-mail"
          register={register}
          name="email"
          isRequired={true}
          error={errors.email}
          bg={company.color}
          input={EmailInput}
        />
        <CheckboxGroup>
          <Flex alignItems="start" justify="start" flexDir="column">
            <Checkbox {...register("approved")} isRequired={true} variant="">
              <PrivacityText link={company.privacy_policy_link} />
            </Checkbox>
          </Flex>
          <Input required={true} type="hidden" />
        </CheckboxGroup>
      </Form>
      <Flex justify="space-between" alignItems="center">
        <SearchSimpleLink
          link={`/formularios/${company.slug}/${search.slug}/cliente/cadastro${
            answer_id ? "/" + answer_id : ""
          }`}
          text="Não tenho cadastro"
        />

        <SubmitButton
          bg={company.color}
          color="white"
          text="Entrar"
          form="login"
          isLoading={formState.isSubmitting}
        />
      </Flex>
    </>
  );
}
