import { parsePhoneNumber } from "react-phone-number-input";

import { PhoneNumberUtil as phoneUtil } from "google-libphonenumber";

const instancePhoneUtil = phoneUtil.getInstance();

export const phoneNumberValidation = (value: string) => {
  try {
    const parsedValue = parsePhoneNumber(value);

    if (!parsedValue) {
      return false;
    }

    const countryCode = parsedValue.country;

    const number = instancePhoneUtil.parseAndKeepRawInput(value, countryCode);

    const isValidNumber = instancePhoneUtil.isValidNumber(number);
    const isPossibleNumber = instancePhoneUtil.isPossibleNumber(number);
    const isValidNumberForRegion = instancePhoneUtil.isValidNumberForRegion(
      number,
      countryCode
    );

    return isValidNumber && isPossibleNumber && isValidNumberForRegion;
  } catch (error) {
    console.error("Phone number validation error:", error);
    return false;
  }
};
