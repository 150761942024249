import { useFormClient } from "../../../hooks/formClient";

import * as yup from "yup";
import { useNavigate, useParams } from "react-router";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCallback } from "react";
import { api } from "../../../services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "@chakra-ui/toast";
import { CompleteClientOnePage } from "../SearchOnePage/completeClient";
import { phoneNumberValidation } from "../../../utils/phoneValidation";

type CompleteClientFormData = {
  phone: string;
  born_date?: string;
  approved: boolean;
};

const createClientFormData = yup.object().shape({
  phone: yup
    .string()
    .required("O Whatsapp é obrigatório")
    .test(
      "is-valid-phone",
      "Número de telefone inválido",
      phoneNumberValidation
    ),
  approved: yup.boolean().nullable(),
  born_date: yup
    .string()
    .nullable()
    .matches(
      /(0?[1-9]|[12]\d|30|31)\/(0[1-9]|1[0-2])\/(19[0-9]{2}|20[0-1][0-9])/,
      "Formato inválido "
    ),
});

export function IndexCompleteClientControl() {
  const { search, company, isTest } = useFormClient();
  const navigate = useNavigate();
  const { slug, search_slug, client_id } = useParams();
  const form = useForm({
    mode: "onBlur",
    resolver: yupResolver(createClientFormData),
  });
  const toast = useToast();

  const handleCompleteClient: SubmitHandler<CompleteClientFormData> =
    useCallback(
      async (formValue) => {
        try {
          if (!isTest) {
            const response = await api.put(`/clientForms/${client_id}`, {
              phone: formValue.phone,
              born_date: formValue.born_date,
              approved: formValue.approved,
            });

            localStorage.setItem(
              `@B2S-${company.slug}:user`,
              JSON.stringify({
                name: response.data.name,
                email: response.data.email,
                id: response.data.id,
              })
            );

            if (response.data.coupons && response.data.coupons.length > 0) {
              navigate(
                `/formularios/${slug}/${search_slug}/obrigado/${response.data.coupons[0].id}`
              );
            } else {
              navigate(`/formularios/${slug}/${search_slug}/obrigado`);
            }
          }
        } catch (err: any) {}
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [client_id, company.slug, navigate, slug, search_slug, toast, isTest]
    );

  switch (search.type_form) {
    case "onepage":
      return (
        <CompleteClientOnePage handle={handleCompleteClient} form={form} />
      );
    case "multipages":
      return (
        <CompleteClientOnePage handle={handleCompleteClient} form={form} />
      );

    default:
      return (
        <CompleteClientOnePage handle={handleCompleteClient} form={form} />
      );
  }
}
