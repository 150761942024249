import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { BornDateInput } from "../../atoms/Input/BornDateInput";
import { PhoneInput } from "../../atoms/Input/PhoneInput";
import { FormControlMaterialUI } from "../../templates/FormControlMaterialUI";
import { Stack } from "@chakra-ui/react";
import { DefaultFormControlMaterialUI } from "../../organisms/DefaultFormControlMaterialUI";
import { CnpjInput } from "../../atoms/Input/CnpjInput";
import { CpfInput } from "../../atoms/Input/CpfInput";
import { EmailInput } from "../../atoms/Input/EmailInput";
interface StoreClientFormProps {
  register: UseFormRegister<any>;
  errors: DeepMap<FieldValues, FieldError>;
  bg: string;
  has_cnpj: boolean;
  has_cpf: boolean;
  is_totem?: boolean;
}

export function StoreClientForm({
  register,
  errors,
  bg,
  has_cnpj,
  has_cpf,
  is_totem,
}: StoreClientFormProps) {
  return (
    <Stack spacing={2}>
      <DefaultFormControlMaterialUI
        register={register}
        text="Nome"
        name="name"
        isRequired={true}
        error={errors.name}
        bg={bg}
        is_totem={is_totem}
      />
      <DefaultFormControlMaterialUI
        register={register}
        text="Sobrenome"
        name="surname"
        isRequired={true}
        error={errors.surname}
        bg={bg}
        is_totem={is_totem}
      />
      {has_cpf && (
        <FormControlMaterialUI
          text="CPF"
          name="cpf"
          register={register}
          isRequired={true}
          bg={bg}
          error={errors.cpf}
          input={CpfInput}
          is_totem={is_totem}
        />
      )}
      <FormControlMaterialUI
        text="Whatsapp"
        name="phone"
        register={register}
        isRequired={true}
        bg={bg}
        error={errors.phone}
        input={PhoneInput}
        is_totem={is_totem}
      />
      <FormControlMaterialUI
        text="E-mail"
        register={register}
        name="email"
        isRequired={true}
        error={errors.email}
        bg={bg}
        input={EmailInput}
        is_totem={is_totem}
      />
      <FormControlMaterialUI
        text="Data de aniversário"
        register={register}
        name="born_date"
        isRequired={false}
        error={errors.born_date}
        bg={bg}
        input={BornDateInput}
        is_totem={is_totem}
      />
      {has_cnpj && (
        <>
          <DefaultFormControlMaterialUI
            register={register}
            text="Nome da sua Empresa"
            name="company_name"
            isRequired={true}
            error={errors.company_name}
            bg={bg}
            is_totem={is_totem}
          />
          <FormControlMaterialUI
            text="CNPJ"
            name="cnpj"
            register={register}
            isRequired={true}
            bg={bg}
            error={errors.cnpj}
            input={CnpjInput}
            is_totem={is_totem}
          />
        </>
      )}
    </Stack>
  );
}
