interface IProps {
  checkValue: string;
  has_replica_promoter?: boolean;
  has_replica_neutral?: boolean;
  has_replica_detractor?: boolean;
  type:
    | "NPS"
    | "CSAT"
    | "Multipla escolha"
    | "Rating"
    | "Emoticon"
    | "Like/Dislike"
    | "Atendente";
}

export function nextStep({
  checkValue,
  has_replica_detractor,
  has_replica_neutral,
  has_replica_promoter,
  type,
}: IProps) {
  const intValue = parseInt(checkValue);

  switch (type) {
    case "Like/Dislike": {
      if (
        (checkValue === "like" && !has_replica_promoter) ||
        (checkValue === "dislike" && !has_replica_detractor)
      ) {
        return true;
      }
      return false;
    }
    case "NPS": {
      if (
        (intValue >= 9 && !has_replica_promoter) ||
        (intValue <= 6 && intValue > 0 && intValue && !has_replica_detractor) ||
        ((intValue === 7 || intValue === 8) && !has_replica_neutral)
      ) {
        return true;
      }
      return false;
    }
    case "CSAT": {
      if (
        (intValue === 5 && !has_replica_promoter) ||
        (intValue <= 2 && intValue > 0 && !has_replica_detractor) ||
        ((intValue === 3 || intValue === 4) && !has_replica_neutral)
      ) {
        return true;
      }
      return false;
    }
    case "Rating": {
      if (
        (intValue === 5 && !has_replica_promoter) ||
        (intValue <= 2 && intValue > 0 && !has_replica_detractor) ||
        ((intValue === 3 || intValue === 4) && !has_replica_neutral)
      ) {
        return true;
      }
      return false;
    }
    case "Emoticon": {
      if (
        (intValue === 5 && !has_replica_promoter) ||
        (intValue <= 2 && intValue > 0 && !has_replica_detractor) ||
        ((intValue === 3 || intValue === 4) && !has_replica_neutral)
      ) {
        return true;
      }
      return false;
    }
    case "Multipla escolha": {
      if (checkValue !== "Outro") {
        return true;
      }
      return false;
    }
    case "Atendente": {
      if (checkValue !== "") {
        return true;
      }
      return false;
    }
    default: {
      return false;
    }
  }
}
