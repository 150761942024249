import { RadioProps } from "@chakra-ui/radio";
import {
  ChakraProvider,
  extendTheme,
  RadioGroup as RadioGroupChakra,
  Stack,
} from "@chakra-ui/react";
import { FormControlQuestionSearch } from "../../../../molecules/FormControl/FormControlQuestionSearch";
import { RadioGroupSearch } from "../../../../molecules/RadioGroup/RadioGroupSearch";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RadioGroupOther } from "../../../../molecules/RadioGroup/RadioGroupOther";
import { InputGroup } from "../../../../molecules/InputGroup";
import { FormErrorMessageDefault } from "../../../../atoms/FormErrorMessage/FormErrorMessageDefault";
import { nextStep } from "../../../../../utils/nextStep";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { ClearButton } from "../../../../atoms/Button/ClearButton";
interface IProps extends RadioProps {
  options: {
    name: string;
    value?: any;
  }[];
  label: string;
  name: string;
  setValue: (value: string) => void;
  selectColor: string;
  other?: boolean;
  unknown?: boolean;
  description?: string;
  defaultValue?: string;
  isRequired: boolean;
  error?:
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | boolean
    | undefined;
  step?: number;
  setStep?: (value: number) => void;
  handleTrigger?: (value: string) => void;
  questionId?: string;
  updateError?: (value: string, error: boolean) => void;
  type?: string;
}

export function MultipleChoiceRadioQuestionDefault({
  options,
  label,
  name,
  setValue,
  selectColor,
  other,
  unknown,
  description,
  defaultValue,
  error,
  step,
  setStep,
  isRequired,
  handleTrigger,
  questionId,
  type,
  updateError,
}: IProps) {
  const [hasError, setHasError] = useState<boolean>(false);
  const [selectOther, setSelectOther] = useState<boolean>(false);
  const [otherValue, setOtherValue] = useState<string>("");

  const formatDefaultValue = useMemo(() => {
    if (defaultValue) {
      let checkIfOther = options.findIndex(
        (option) => option.name === defaultValue
      );
      if (checkIfOther === -1 && defaultValue !== "Não me recordo do nome") {
        setSelectOther(true);
        setOtherValue(defaultValue);
        return "Outro";
      }
      return defaultValue;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, options]);

  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    formatDefaultValue
  );

  useEffect(() => {
    setHasError(!!error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleRadioGroup = useCallback(
    (value: string) => {
      setSelectedValue(value);
      if (value === "Outro") {
        setValue("");
        setSelectOther(true);
        if (updateError && questionId && error) {
          updateError(`quizResponses.${questionId}`, error as boolean);
        }
      } else {
        setValue(value);
        setOtherValue("");
        setSelectOther(false);
        if (updateError && questionId && error) {
          updateError(`quizResponses.${questionId}`, error as boolean);
        }
      }
      if (step !== undefined && setStep !== undefined) {
        const checkNextStep = nextStep({
          checkValue: value,
          type: "Multipla escolha",
        });
        if (checkNextStep) {
          setStep(step + 1);
          if (handleTrigger) {
            handleTrigger(`quizResponses.${questionId}`);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue, setSelectedValue]
  );

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setOtherValue(value);
    if (value.trim() === "") {
      setValue("");
    } else {
      setValue(value);
    }
  };
  const theme = extendTheme({
    components: {
      Radio: {
        baseStyle: {
          control: {
            _checked: {
              bg: selectColor,
              borderColor: selectColor,
              _hover: {
                bg: selectColor,
                borderColor: selectColor,
              },
            },
          },
        },
      },
      Checkbox: {
        baseStyle: {
          control: {
            _checked: {
              bg: selectColor,
              borderColor: selectColor,
              _hover: {
                bg: selectColor,
                borderColor: selectColor,
              },
            },
          },
        },
      },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <FormControlQuestionSearch
        isRequired={isRequired}
        label={label}
        name={name}
        description={description}
      >
        {hasError && <FormErrorMessageDefault error="Questão obrigatória" />}
        <RadioGroupChakra
          onChange={handleRadioGroup}
          colorScheme="green"
          value={selectedValue ?? ""}
        >
          <Stack direction="column">
            {options.map((option) => (
              <RadioGroupSearch
                setSelectOther={setSelectOther}
                selectColor={selectColor}
                option={option}
              />
            ))}
            {type === "Atendente" && unknown && (
              <RadioGroupSearch
                selectColor={selectColor}
                setSelectOther={setSelectOther}
                option={{
                  name: "Não me recordo do nome",
                  value: "Não me recordo do nome",
                }}
              />
            )}
            {type !== "Atendente" && other && (
              <RadioGroupOther
                selectColor={selectColor}
                setSelectOther={setSelectOther}
              />
            )}
            {type !== "Atendente" && other && selectOther && (
              <InputGroup
                selectColor={selectColor}
                name={`${name}`}
                setValue={handleInputChange}
                defaultValue={otherValue}
                error=""
                isRequired={isRequired}
              />
            )}
          </Stack>
        </RadioGroupChakra>
      </FormControlQuestionSearch>
      {!isRequired && selectedValue && (
        <ClearButton
          onClick={() => {
            setValue("");
            setOtherValue("");
            setSelectOther(false);
            setSelectedValue(undefined);
          }}
        />
      )}
    </ChakraProvider>
  );
}
