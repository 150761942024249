import { useFormClient } from "../../../hooks/formClient";
import { useCallback, useState } from "react";
import { Flex, SimpleGrid } from "@chakra-ui/layout";
import { MultipleChoiceRadioQuestionDefault } from "../../../components/organisms/RadioQuestion/MultipleChoiceRadioQuestion/MultipleChoiceRadioQuestionDefault";
import { HTMLText } from "../../../components/atoms/Div/HTMLText";
import { Button } from "@chakra-ui/react";
import { ReactSelectQuestion } from "../../../components/organisms/SelectQuestion/ReactSelectQuestion";
import { Loader } from "../../../components/templates/Loader";
import { FieldValues, SubmitHandler } from "react-hook-form";
import shade from "polished/lib/color/shade";

interface Search {
  id: string;
  slug: string;
  has_page: boolean;
  reverse_order: boolean;
  anonymous_search: boolean;
  has_linktree?: boolean;
  unique_answer_client: boolean;
  company_name: string;
  company_slug: string;
}

interface UnifyQrcode {
  question_unity: string;
  form_message: string;
  list_view: string;
}
interface IProps {
  searches: Search[];
  unifyQrcode: UnifyQrcode;
  radical: string;
}

export function SearchUnits({ searches, unifyQrcode, radical }: IProps) {
  const { company, questionQuery, page, whiteLabel } = useFormClient();
  const [selectPage, setSelectPage] = useState("");

  const handleSubmitPage: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      const findSearch = searches.find((search) => search.id === selectPage);

      if (findSearch) {
        if (findSearch.has_linktree) {
          window.location.assign(
            `/formularios/${findSearch.company_slug}/${findSearch.slug}/linktree`
          );
        } else {
          if (findSearch.reverse_order) {
            if (
              findSearch.unique_answer_client &&
              findSearch.anonymous_search
            ) {
              window.location.assign(
                `/formularios/${findSearch.company_slug}/${findSearch.slug}/cliente/cadastro`
              );
            } else {
              window.location.assign(
                `/formularios/${findSearch.company_slug}/${findSearch.slug}/cliente/inicial`
              );
            }
          } else {
            if (findSearch.has_page) {
              if (page) {
                window.location.assign(
                  `/formularios/${findSearch.company_slug}/${findSearch.slug}/${page}?${questionQuery}`
                );
              } else {
                window.location.assign(
                  `/formularios/${findSearch.company_slug}/${findSearch.slug}/pagina/inicial`
                );
              }
            } else {
              window.location.assign(
                `/formularios/${findSearch.company_slug}/${findSearch.slug}?${questionQuery}`
              );
            }
          }
        }
      }
    },
    [page, questionQuery, searches, selectPage]
  );
  return (
    <Loader
      isLoading={searches && searches.length !== 0}
      whiteLabel={whiteLabel}
    >
      <HTMLText html={unifyQrcode.form_message} />
      <Flex as="form" direction="column" onSubmit={handleSubmitPage}>
        <SimpleGrid spacingY={4} columns={1} mt="3">
          {unifyQrcode.list_view === "checkbox" ? (
            <MultipleChoiceRadioQuestionDefault
              label={unifyQrcode.question_unity}
              name="search_id"
              options={searches
                .sort((a, b) => (a.company_name < b.company_name ? 1 : -1))
                .map((search) => ({
                  name: search.company_name.replace(
                    radical ? radical : "*-",
                    ""
                  ),
                  value: search.id,
                }))}
              setValue={(value: string) => {
                setSelectPage(value);
              }}
              isRequired
              selectColor={company.color}
            />
          ) : (
            <ReactSelectQuestion
              label={unifyQrcode.question_unity}
              name="search_id"
              options={searches
                .sort((a, b) => (a.company_name > b.company_name ? 1 : -1))
                .map((search) => ({
                  name: search.company_name.replace(
                    radical ? radical : "*-",
                    ""
                  ),
                  value: search.id,
                }))}
              setValue={(value: string) => {
                setSelectPage(value);
              }}
              isRequired
            />
          )}
        </SimpleGrid>
        <Flex justify="flex-end">
          <Button
            type="submit"
            form="send-form"
            onClick={handleSubmitPage}
            fontWeight="normal"
            color="#fff"
            bg={company.color}
            _hover={{
              bg: shade(0.3, company.color),
            }}
            maxW="40"
            disabled={selectPage !== "" ? false : true}
          >
            Enviar
          </Button>
        </Flex>
      </Flex>
    </Loader>
  );
}
