import { HStack } from "@chakra-ui/layout";
import { RadioProps, useRadioGroup } from "@chakra-ui/radio";
import { useEffect, useMemo, useState } from "react";
import { FormControlQuestionSearch } from "../../../../molecules/FormControl/FormControlQuestionSearch";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { LikeDislikeRadioCard } from "../../../../molecules/RadioCard/LikeDislikeRadioCard";
import { FormatReplic } from "../../../../templates/FormatReplic";
import { FormErrorMessageDefault } from "../../../../atoms/FormErrorMessage/FormErrorMessageDefault";
import { nextStep } from "../../../../../utils/nextStep";
import { FieldError, Merge, FieldErrorsImpl } from "react-hook-form";
import { ClearButton } from "../../../../atoms/Button/ClearButton";

interface IProps extends RadioProps {
  label: string;
  name: string;
  setValue: (value: string, replica: string) => void;
  description?: string;
  has_promoter_replica?: boolean;
  has_neutral_replica?: boolean;
  has_detractor_replica?: boolean;
  promoter_replica?: string;
  neutral_replica?: string;
  detractor_replica?: string;
  selectColor: string;
  defaultValue?: string;
  defaultReplica?: string;
  error?:
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | boolean
    | undefined;
  step?: number;
  setStep?: (value: number) => void;
  handleTrigger?: (value: string) => void;
  questionId?: string;
  updateError?: (value: string, error: boolean) => void;
}

export function LikeDislikeRadioQuestionDefault(props: IProps) {
  const [hasError, setHasError] = useState<boolean>();
  const [selected, setSelected] = useState<string>(props.defaultValue ?? "");
  const [replica, setReplica] = useState<string>(props.defaultReplica ?? "");
  const options = ["dislike", "like"];
  const [focus, setFocus] = useState<boolean>(false);
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: props.name,
    value: selected,
    onChange: (newValue) => {
      setSelected(newValue);
      props.setValue(newValue, replica);
      if (props.updateError && props.questionId && props.error) {
        props.updateError(
          `quizResponses.${props.questionId}`,
          props.error as boolean
        );
      }
      if (props.step !== undefined && props.setStep !== undefined) {
        const checkNextStep = nextStep({
          checkValue: newValue,
          has_replica_detractor: props.has_detractor_replica,
          has_replica_neutral: props.has_neutral_replica,
          has_replica_promoter: props.has_promoter_replica,
          type: "Like/Dislike",
        });
        if (checkNextStep) {
          props.setStep(props.step + 1);
          if (props.handleTrigger) {
            props.handleTrigger(`quizResponses.${props.questionId}`);
          }
        }
      }
    },
  });

  const sortingValue = useMemo(() => {
    if (selected === "like") {
      return "promoter";
    } else if (selected === "dislike") {
      return "detractor";
    }
    return "";
  }, [selected]);

  const group = getRootProps();

  useEffect(() => {
    setHasError(!!props.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error]);

  return (
    <>
      <FormControlQuestionSearch
        isRequired={props.isRequired}
        label={props.label}
        name={props.name}
        description={props.description}
      >
        {hasError && <FormErrorMessageDefault error="Questão obrigatória" />}
        <HStack wrap="wrap" justifyContent="center" {...group}>
          {options.map((value) => {
            const radio = getRadioProps({ value });

            return (
              <LikeDislikeRadioCard
                type={value}
                h="80px"
                w="80px"
                p="20px"
                {...radio}
              >
                {value === "like" ? (
                  <FaRegThumbsUp
                    color={selected === "like" ? "#fff" : "#41E93E"}
                    size={40}
                  />
                ) : (
                  <FaRegThumbsDown
                    color={selected === "dislike" ? "#fff" : "#E93E3E"}
                    size={40}
                  />
                )}
              </LikeDislikeRadioCard>
            );
          })}
        </HStack>
      </FormControlQuestionSearch>
      <FormatReplic
        detractor_replica={props.detractor_replica}
        neutral_replica={props.neutral_replica}
        promoter_replica={props.promoter_replica}
        sortingValue={sortingValue}
        value={selected}
        has_detractor_replica={props.has_detractor_replica}
        has_neutral_replica={props.has_neutral_replica}
        has_promoter_replica={props.has_promoter_replica}
        focus={focus}
        color={props.selectColor}
        setValue={(value: any, result: string) => props.setValue(value, result)}
        setReplica={(value: string) => setReplica(value)}
        setFocus={(value: boolean) => setFocus(value)}
        defaultReplica={replica}
      />
      {!props.isRequired && selected && (
        <ClearButton
          onClick={() => {
            setSelected("");
            setReplica("");
            props.setValue("", "");
          }}
        />
      )}
    </>
  );
}
