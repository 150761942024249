import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useFormClient } from "../../../hooks/formClient";
import sha256 from "crypto-js/sha256";
import { Loader } from "../../../components/templates/Loader";

export function LoaderForm() {
  const {
    company,
    search,
    page,
    questionQuery,
    client,
    dispatch,
    isTest,
    checkDispatch,
    whiteLabel,
  } = useFormClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (checkDispatch) {
      if (search.id !== undefined) {
        if (!search.active) {
          navigate(`/formularios/${company.slug}/${search.slug}/desativada`);
        } else {
          localStorage.removeItem(`@B2S:link`);
          localStorage.setItem(
            `@B2S:link`,
            sha256(new Date().toString()).toString()
          );
          if (search.is_enps) {
            if (search.has_page) {
              if (page) {
                navigate(
                  `/formularios/${company.slug}/${search.slug}/${page}?${
                    dispatch ? `d=${dispatch}` : ""
                  }${questionQuery}`
                );
              } else {
                navigate(
                  `/formularios/${company.slug}/${search.slug}/pagina/inicial`
                );
              }
            }
            navigate(
              `/formularios/${company.slug}/${search.slug}?${
                dispatch ? `d=${dispatch}` : ""
              }${questionQuery}`
            );
          } else {
            if (
              search.unifyQrcodesSearches &&
              search.unifyQrcodesSearches.length > 0 &&
              !client
            ) {
              if (
                search.unifyQrcodesSearches[0].unifyQrcode &&
                search.unifyQrcodesSearches[0].unifyQrcode
                  .unifyQrcodesFranchisesGroupUnifyQrcodes &&
                search.unifyQrcodesSearches[0].unifyQrcode
                  .unifyQrcodesFranchisesGroupUnifyQrcodes.length > 0
              ) {
                navigate(
                  `/formularios/${company.slug}/${search.slug}/grupos/${search.unifyQrcodesSearches[0].unifyQrcode.unifyQrcodesFranchisesGroupUnifyQrcodes[0].unify_qrcode_franchise_group_id}`
                );
              } else {
                navigate(
                  `/formularios/${company.slug}/${search.slug}/unidades/${search.unifyQrcodesSearches[0].unify_qrcode_id}`
                );
              }
            } else {
              if (search.has_linktree && !dispatch && !isTest) {
                navigate(
                  `/formularios/${company.slug}/${search.slug}/linktree`
                );
              } else {
                if (search.reverse_order && !client) {
                  if (search.unique_answer_client && search.anonymous_search) {
                    navigate(
                      `/formularios/${company.slug}/${search.slug}/cliente/cadastro`
                    );
                  } else {
                    navigate(
                      `/formularios/${company.slug}/${search.slug}/cliente/inicial`
                    );
                  }
                } else {
                  if (search.has_page) {
                    if (page) {
                      if (client) {
                        navigate(
                          `/formularios/${company.slug}/${
                            search.slug
                          }/${page}/${client}?${
                            dispatch ? `d=${dispatch}` : ""
                          }${questionQuery}`
                        );
                      } else {
                        navigate(
                          `/formularios/${company.slug}/${
                            search.slug
                          }/${page}?${
                            dispatch ? `d=${dispatch}` : ""
                          }${questionQuery}`
                        );
                      }
                    } else {
                      if (client) {
                        navigate(
                          `/formularios/${company.slug}/${search.slug}/pagina/inicial/${client}`
                        );
                      } else {
                        navigate(
                          `/formularios/${company.slug}/${search.slug}/pagina/inicial`
                        );
                      }
                    }
                  } else {
                    if (search.id === "9bcde58d-5c8d-495c-8234-7705a242b2d2") {
                      navigate(
                        `/formularios/${company.slug}/${search.slug}/brigaderia`
                      );
                    } else {
                      if (client) {
                        navigate(
                          `/formularios/${company.slug}/${
                            search.slug
                          }/${client}?${
                            dispatch ? `d=${dispatch}` : ""
                          }${questionQuery}`
                        );
                      } else {
                        navigate(
                          `/formularios/${company.slug}/${search.slug}?${
                            dispatch ? `d=${dispatch}` : ""
                          }${questionQuery}`
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [
    checkDispatch,
    client,
    company,
    dispatch,
    navigate,
    isTest,
    page,
    questionQuery,
    search,
    search.slug,
  ]);

  return <Loader isLoading={false} whiteLabel={whiteLabel} children={<></>} />;
}
