import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import { api } from "../services/api";

interface Search {
  id: string;
  name: string;
  cover_url: string;
  company_id: string;
  client_message: string;
  slug: string;
  has_page: boolean;
  form_message: string;
  discursive_question: string;
  coupon_message: string;
  nps: string;
  question_page?: string;
  name_external_link?: string;
  external_link?: string;
  is_totem?: boolean;
}

interface Bonus {
  name: string;
  total_visits: number;
}

interface Feature {
  facebook_pixel?: string;
}
interface Company {
  id: string;
  name: string;
  genre: string;
  slug: string;
  logo_url: string;
  logo: string;
  color: string;
  privacy_policy_link?: string;
  secondary_color: string;
}

interface FidelityProgram {
  id: string;
  name: string;
  reward: string;
  rules: string;
}

interface FormFidelityProgramProviderProps {
  children: ReactNode;
}

interface WhiteLabel {
  name: string;
  form_domain: string;
  logo_horizontal_url: string;
  logo_vertically_url: string;
  primary_color: string;
  secondary_color: string;
  favicon_url: string;
}

interface FormFidelityProgramContextProps {
  search: Search;
  company: Company;
  feature: Feature;
  fidelityProgram: FidelityProgram;
  replaceQuestion: string;
  bonuses: Bonus[];
  whiteLabel?: WhiteLabel;
  updateWhiteLabel: (whiteLabel: WhiteLabel) => void;
}
export const FormFidelityProgramContext =
  createContext<FormFidelityProgramContextProps>(
    {} as FormFidelityProgramContextProps
  );

export function useFormFidelityProgram(): FormFidelityProgramContextProps {
  const context = useContext(FormFidelityProgramContext);

  if (!context) {
    throw new Error(
      "useFormFidelityProgram must be used within as AuthProvider"
    );
  }

  return context;
}
export function FormFidelityProgramProvider({
  children,
}: FormFidelityProgramProviderProps) {
  const location = useLocation();
  const [search, setSearch] = useState<Search>({} as Search);
  const [company, setCompany] = useState<Company>({} as Company);
  const [whiteLabel, setWhiteLabel] = useState<WhiteLabel>();

  const [feature, setFeature] = useState<Feature>({} as Feature);
  const [bonuses, setBonuses] = useState<Bonus[]>([]);
  const [fidelityProgram, setFidelityProgram] = useState<FidelityProgram>(
    {} as FidelityProgram
  );
  const replaceQuestion = useMemo(() => {
    if (company) {
      if (company.genre === "F") {
        return `a ${company.name}`;
      } else {
        return `o ${company.name}`;
      }
    }
    return "";
  }, [company]);

  useEffect(() => {
    const [, , slug, search_slug] = location.pathname.split("/");
    api
      .get(`/fidelityPrograms/search-company/${slug}/${search_slug}`)
      .then((response) => {
        setSearch(response.data.search);
        setCompany(response.data.company);
        setBonuses(response.data.bonuses);
        setFeature(response.data.feature);
        setFidelityProgram(response.data.fidelityProgram);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateWhiteLabel = useCallback((whiteLabel: WhiteLabel) => {
    setWhiteLabel(whiteLabel);
  }, []);
  return (
    <FormFidelityProgramContext.Provider
      value={{
        fidelityProgram,
        search,
        company,
        feature,
        replaceQuestion,
        bonuses,
        whiteLabel,
        updateWhiteLabel,
      }}
    >
      {children}
    </FormFidelityProgramContext.Provider>
  );
}
